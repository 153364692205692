import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Button, Dropdown, ButtonGroup, Breadcrumb } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faPlus, faEye, faEdit, faTrash, faEllipsisH, faHome } from '@fortawesome/free-solid-svg-icons';
import {Modal, Table  } from "react-bootstrap";
import { useSnackbar } from 'notistack';
import {Helmet} from 'react-helmet-async';
import { ProductContext } from '../../../ProductProvider';
import Preloader from '../../../components/Preloader/Preloader';
import TopStoriesForm from './TopStoriesForm';
const config = require('../../../config.json');

export default function TopStoriesMain() {
    const { validateLogin, loggedUser, generateDate} = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(false);
    const [formType, setFormType]=useState("View");
    const [newCollapsed, setNewCollapsed] = useState(true);
    const [topStory, setTopStory]=useState({eventDate:'', displayStatus:'Active', timelineDisplay:true, timelineTitle:'',timelineHeader:'', timelineDescription:'', timelineImage:"",timelineColor:"#563d7c", displayOrder:0});
    const [imageFile, setImageFile] = useState('');
    const [activePost, setActivePost]=useState([]);
    const [selectedItem, setSelectedItem]=useState('');

    useEffect(() => {
        if(loggedUser.isAuthenticated){
             fetchActiveInfo();
         }else{
             validateLogin();
         } 
         return ()=> {
           //  resetFields();
         }      
     }, [loggedUser]);

    const fetchActiveInfo =async()=>{
        try{
            setLoaded(false);
           //console.log("Testing");
           //const session = await Auth.currentSession();
           //const access_token=session.idToken.jwtToken;
           const { idToken } = (await fetchAuthSession()).tokens ?? {};
           //const test = await fetchAuthSession();
            const startDate=await generateDate();
            //console.log("start Date : ", startDate);
            const clientID="VJCollege";
            const searchOption="Top Story";
        await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
            headers: {
              Authorization: idToken,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log(response);
            if(response.data.length>0){

            const test=response.data;
            //const result=test.sort((a,b) => (a.updatedOn > b.updatedOn) ? 1 : ((b.updatedOn > a.updatedOn) ? -1 : 0))
            const result=test.sort((a,b) => new Date(b.startDate) - new Date(a.startDate) ); // b - a for reverse sort
            //console.log("Reverse order : ", result);
            //setInsights(result);
            setActivePost(result);

            }

           // setGalleryImages(response.data);
           enqueueSnackbar(response.data.length + " - Active Post available", {variant:'success'}); 
            setLoaded(true);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
        }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
        })
    }catch(error){
		//console.log(error);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        //setDispMsg(error.message);
    }
    }


    const handleStoryAdd=async(event)=>{
        event.preventDefault();
        setFormType('Add')
        newToggle();
    }

    const newToggle = () => {
        // console.log("clicked");
             setNewCollapsed(!newCollapsed);
         }

         const validateFields=()=>{
            var returnValue="Passed";
                if(topStory.timelineTitle===""){
                    enqueueSnackbar("Title cannot be Empty",{variant:'info'}); 
                    returnValue="Failed";
                }else if(topStory.timelineHeader===""){
                    enqueueSnackbar("Header cannot be Empty",{variant:'info'}); 
                    returnValue="Failed";
                }else if(topStory.timelineDescription===""){
                    enqueueSnackbar("Story Details cannot be Empty",{variant:'info'}); 
                    returnValue="Failed";
                }else if(topStory.timelineColor===""){
                    enqueueSnackbar("Header Color cannot be null",{variant:'info'}); 
                    returnValue="Failed";
                }else{
                    returnValue="Passed"; 
                }
              //window.scrollTo(0,0);
              return returnValue;
        }

        const postOnS3 = async(fileName)=>{
            try{   
                    //const fileName=newFileName
                //  const fileName="infoDesk_"+new Date().getTime();
                // const newFileName=newDoctorDept+"$"+newDoctorName+".jpeg";
                const tnewFileName=fileName+".jpeg";
                const keyName='/images/topstories';
            
                const bucketName=config.s3.bucketName+keyName;
                //console.log("bucket name : ", bucketName);
                console.log("file name : ", imageFile);
            
                var tfile = imageFile;
                var blob = tfile.slice(0, tfile.size, 'image/jpeg'); 
                const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
                //console.log("New file : ", newFile);
                //const session = await Auth.currentSession();
                //const access_token=session.idToken.jwtToken;
                //console.log("access token : ", access_token);
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                await axios.get(`${config.api.invokeURL}/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
    
    //            await axios.get(`${config.api.invokeURL}/dashboard/images/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
                    headers:{
                        Authorization: idToken,
                        'x-api-key': config.api.key
                    }       }).then(async(response)=>{
             //   console.log("Response : ", response);
                const res=await fetch(response.data.uploadURL, { method: 'PUT',
                headers:{
                    "Content-Type":"image/jpeg"
                    },body: newFile});
                    //console.log("Res : ", res);
                    if(res.status===200){
                    //  fetchGalleryImages();
                        //setProfileImage(tnewFileName);
                        //var timestamp = new Date().getTime();     
                        // var tprofile = document.getElementById("profile");
                        //var imgsrc="";
                        //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                        //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                        // tprofile.src=imgsrc;
                        //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                        //console.log("tprofle : ", tprofile.src);
                        //  setLoaded(true);
                        //  newToggle();
                        // fetchGalleryImages();
                    }
                }).catch((error)=>{
                    console.log("error1:", error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                    setLoaded(true);
            })
            
            }catch(error){
            console.log("error1:", error);
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
            }
    
        }
    
    

    const AddNewMessage=async(e)=>{
        e.preventDefault();
        //console.log("params :", topStory);
        //console.log("test", topStory.timelineHeader);
       // console.log("test", temp.toDateString());
        try{
            const validationResult=await validateFields();
            if(validationResult==="Passed"){
                setLoaded(false);
                const msgid=new Date().getTime().toString();
                const todayDate=await generateDate();
                var ttopStory=topStory;        
                if(imageFile){
                    ttopStory.timelineImage=msgid+".jpeg";
                    await postOnS3(msgid);
                }
                const inputParams={
                    clientID:"VJCollege",
                    msgID:msgid,
                    startDate:topStory.eventDate,
                    endDate:topStory.eventDate,
                    msgCategory:"Top Story",
                    msgStatus:topStory.displayStatus,
                    flashNews:{},
                    scrollMsg:{},
                    faithMsg:{},
                    topStory:ttopStory,
                    updatedBy:loggedUser.name,
                    updatedOn:todayDate
                };
                // console.log("params : ", inputParams);
                 setLoaded(true);

                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                await axios.post(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`, inputParams,{
                    headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                        enqueueSnackbar(msgid+ "  - Successfully Added",{variant:'success'}); 
                        newToggle();
                        fetchActiveInfo();
                        setLoaded(true);
                        }).catch((error)=>{
                            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    })
        }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
        }

    }

    const EditMessage=async(event)=>{
        event.preventDefault();
        try{
            const validationResult=await validateFields();
            if(validationResult==="Passed"){
                const todayDate=await generateDate();
                const msgid=selectedItem.msgID;
                var ttopStory=topStory;
                if(imageFile){
                    ttopStory.timelineImage=msgid+".jpeg";
                    await postOnS3(msgid);
                }
                const inputParams={
                    clientID:"VJCollege",
                    msgID:msgid,
                    startDate:topStory.eventDate,
                    endDate:topStory.eventDate,
                    msgCategory:"Top Story",
                    msgStatus:topStory.displayStatus,
                    flashNews:{},
                    scrollMsg:{},
                    faithMsg:{},
                    topStory:ttopStory,
                    updatedBy:loggedUser.name,
                    updatedOn:todayDate,
                    updateType:"Edit"
                }; 
                //console.log("params : ", inputParams);
             const { idToken } = (await fetchAuthSession()).tokens ?? {}; 
                await axios.patch(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`, inputParams,{
                headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                       // console.log("Response : ", response);
                        enqueueSnackbar("Successfully Updated",{variant:'success'}); 
                    newToggle();
                    fetchActiveInfo();
                    setLoaded(true);
                    }).catch((error)=>{
                        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    // setDispMsg(error.message);
                    // hideLoader();
                    })
        }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
    }

    /*const handleProjectRemove=async(event,item)=>{
        event.preventDefault();
        try{
                const todayDate=await generateDate();
                const msgid=item.msgID;
                const inputParams={
                    clientID:"VJCollege",
                    msgID:msgid,
                    updatedBy:loggedUser.name,
                    updatedOn:todayDate,
                    updateType:"Remove"
                }; 
                //console.log("params : ", inputParams);
             const { idToken } = (await fetchAuthSession()).tokens ?? {}; 
                await axios.patch(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`, inputParams,{
                headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                       // console.log("Response : ", response);
                        enqueueSnackbar("Successfully In-Activated",{variant:'success'}); 
                   // newToggle();
                    fetchActiveInfo();
                    setLoaded(true);
                    }).catch((error)=>{
                        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    // setDispMsg(error.message);
                    // hideLoader();
                    })
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
    }*/

   /* const handleProjectDelete =async(event, item)=>{
        event.preventDefault();
        try{
                const msgid=item.msgID;
                //const session = await Auth.currentSession();
                //const access_token=session.idToken.jwtToken;   
                const { idToken } = (await fetchAuthSession()).tokens ?? {};  
                await axios.delete(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`,{
                headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                        enqueueSnackbar(item.msgID+ "  - Successfully Deleted",{variant:'success'}); 
                    fetchActiveInfo();
                    setLoaded(true);            
                    }).catch((error)=>{
                        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    })
        }
        catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
    }*/

    const handleProjectView=(event, item)=>{
        event.preventDefault();
        setTopStory(item.topStory);
        const tImage=config.s3.scrollUrl+item.scrollMsg.image;
        setSelectedItem(item);
        setFormType("View");
        newToggle();
    }

    const handleProjectEdit=(event, item)=>{
        event.preventDefault();
        //console.log("key : ", item);
        setTopStory(item.topStory);
        const tImage=config.s3.scrollUrl+item.scrollMsg.image;
        setFormType("Edit");
        setSelectedItem(item);
        newToggle();
    }

  return (
    <div>
      {!loaded && <Preloader show={loaded ? false : true} />}

    <h3>VJ Stories</h3>

    <Modal onHide={newToggle} show={!newCollapsed}>
            <Modal.Header toggle={newToggle}>
            <Modal.Title>VJ Stories - {formType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TopStoriesForm topStory={topStory} setTopStory={setTopStory} formType={formType} setImageFile={setImageFile}/>
            </Modal.Body>
            <Modal.Footer> 
      {formType==="Add"?<Button
        color="primary"
        onClick={event=>AddNewMessage(event)}
      >
        Add Message       
        </Button>:formType==="Edit"?<Button
        color="primary"
        onClick={EditMessage}
      >
        Edit Message       
        </Button>:null}
  {' '}
      <Button onClick={newToggle}>
        Close
      </Button>
    </Modal.Footer>
            </Modal>    


    <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{handleStoryAdd(event)}} className="buttonStyle me-2">
            <i className="fa fa-plus me-2" aria-hidden="true"></i>
           {/*} <FontAwesomeIcon icon={faPlus} className="me-2" />*/}
            <span>New</span>
          </Dropdown.Toggle>
    </Dropdown>

    <Table striped bordered hover>
             <thead className='theadStyle'>
                <tr>
                <th>#</th>
                <th>Message ID</th>
                <th>Header</th>
                <th>Title</th>
                <th>Display</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody className='tbodyStyle'>
            {loaded && activePost.map((item, index)=>

            <tr key={index} className='trStyle'>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.msgID}
            </td>
            <td>
                {item.topStory.timelineHeader}
            </td>
            <td>
                {item.topStory.timelineTitle}
            </td>
            <td>
                {item.msgStatus==="Active"?<i className="fa fa-check" aria-hidden="true"></i>
                :<i className="fa fa-times" aria-hidden="true"></i>}
                </td>

            <td>
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>

               {/*} <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleProjectView(event, item)}}>
                {/*<FontAwesomeIcon icon={faEye} className="me-2" />*/} 
                <i className="fa fa-eye me-2" aria-hidden="true">View</i>

              </Dropdown.Item>
                <Dropdown.Item onClick={(event)=>{handleProjectEdit(event, item)}}>
               {/*} <FontAwesomeIcon icon={faEdit} className="me-2" />*/}
               <i className="fa fa-pencil me-2" aria-hidden="true">  Edit</i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleProjectView(event, item)}}/>
            <FontAwesomeIcon icon={faEdit} className="me-2" onClick={(event)=>{handleProjectEdit(event, item)}}/>
            <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleProjectDelete(event, item)}}/>

            {/*<button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>
            </tr>)}
            </tbody>
            </Table>


    </div>
  )
}
