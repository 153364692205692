import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import sidebarBg from '../../assets/images/bg1.jpg';
import '../../styles.css';
import '../../../node_modules/react-pro-sidebar/dist/css/styles.css';
import 'react-pro-sidebar/dist/css/styles.css';

import { ProductContext } from "../../ProductProvider";

const Sidebar = ({
  image,
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedLeftChange,
  handleCollapsedRightChange
}) => {
  const { loggedUser } = useContext(ProductContext);

  return (
    <div className='sidebar'>
    <ProSidebar
      image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={true}
      onToggle={handleToggleSidebar}
      breakPoint="md"
    >
      {/* Header */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem
              icon={<i className="fa fa-angle-double-right" aria-hidden="true"></i>}
              onClick={handleCollapsedRightChange}
            ></MenuItem>
          ) : (
            <MenuItem 
              suffix={<i className="fa fa-angle-double-left" aria-hidden="true"></i>}
              onClick={handleCollapsedLeftChange}
            >
              <div className="col d-flex justify-content-center text-center">
              <img src={logo} style={{width:"60px", height:"60px", borderRadius:'50%'}} />
              </div>
             {/*} <div className="col d-flex justify-content-center text-center"
                style={{
                  padding: '9px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: 15,
                  letterSpacing: '1px'
                }}
              >
                Vidyajyoti College
              </div>*/}
              <div className="col d-flex justify-content-center text-center">
                {loggedUser.name}
                <br />
                ({loggedUser.profile})
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem             
          icon={<i className="fa fa-tachometer" aria-hidden="true"></i>}><Link to="/" > Dashboard </Link> </MenuItem>
        <SubMenu title="Registration"
            icon={<i className="fa fa-list-alt" aria-hidden="true"></i>}>
         <MenuItem prefix={<i className="fa fa-plus" aria-hidden="true"></i>}><Link  to="/registration/new"> New</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-cog" aria-hidden="true"></i>}><Link  to="/registration/manage">  Manage </Link></MenuItem>
        </SubMenu>
        <SubMenu title="Student" icon={<i className="fa fa-user"></i>}>
         <MenuItem prefix={<i className="fa fa-search" aria-hidden="true"></i>}><Link  to="/student/search">Search</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-id-card" aria-hidden="true"></i>}><Link  to="/student/marksheet">Mark Sheet</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-sign-in" aria-hidden="true"></i>}><Link  to="/student/login">  Login </Link></MenuItem>
        </SubMenu>
        <SubMenu title="Staff" icon={<i className="fa fa-user"></i>}>
         <MenuItem prefix={<i className="fa fa-search" aria-hidden="true"></i>}><Link  to="/staff/search">Search</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-id-card" aria-hidden="true"></i>}><Link  to="/staff/register">Register</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-sign-in" aria-hidden="true"></i>}><Link  to="/staff/login">  Login </Link></MenuItem>
        </SubMenu>
        <SubMenu title="Web Site" icon={<i className="fa fa-globe" aria-hidden="true"></i>}>
         <MenuItem prefix={<i className="fa fa-slideshare" aria-hidden="true"></i>}><Link  to="/slider-viewer"> Slider</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-picture-o" aria-hidden="true"></i>}><Link  to="/gallery-viewer">  Gallery </Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-inbox" aria-hidden="true"></i>}><Link  to="/infodesk">  Infodesk </Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-archive" aria-hidden="true"></i>}><Link  to="/topstories">  Top Stories </Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-calendar" aria-hidden="true"></i>}><Link  to="/upcomingevents">  Up Coming Events </Link></MenuItem>
        {/*} <MenuItem prefix={<i className="fa fa-book" aria-hidden="true"></i>}><Link  to="/latestarrivals">  Latest Arrivals </Link></MenuItem>*/}
        </SubMenu>
        <SubMenu title="Publications" icon={<i className="fa fa-book" aria-hidden="true"></i>}>
        <MenuItem prefix={<i className="fa fa-id-card-o" aria-hidden="true"></i>}><Link to="/publications/vjtoday">Manage</Link></MenuItem>
        </SubMenu>
        <SubMenu title="Insights" icon={<i className="fa fa-tachometer" aria-hidden="true"></i>}>
        <MenuItem prefix={<i className="fa fa-user" aria-hidden="true"></i>}><Link to="/insights/students">Students</Link></MenuItem>
        </SubMenu>
        </Menu>
      </SidebarContent>
      {/* Footer */}
      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper" style={{ padding: '16px' }}>
          <Link
            className="sidebar-btn"
            style={{ cursor: 'pointer' }}
            to="/logout"
          >
             <i className="fa fa-sign-out" aria-hidden="true"></i>
            <span>Logout</span>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
    </div>
  );
};

export default Sidebar;
