import React, {useEffect, useState, useContext} from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ThemeAction from './redux/actions/ThemeAction'
import Topnav from './components/topnav/TopNav';
import LoginPage from './pages/auth/LoginPage';
import ForgotPassword from './pages/auth/ForgotPassword';
import {ProductContext} from './ProductProvider';
import LandingPage from './pages/LandingPage';
import SliderViewer from './pages/website/SliderViewer';
import ChangePassword from './pages/auth/ChangePassword';
import GalleryViewer from './pages/website/GalleryViewer';
import ListAdmins from './pages/admin/ListAdmins';
import Sidebar from './components/prosidebar/Sidebar';
import VJToday from './pages/publications/VJToday';
import LogoutPage from './pages/auth/LogoutPage';
//import { FaBars } from 'react-icons/fa';
import './App.css';
import AdminForm from './pages/admin/AdminForm';
//import RegistrationForm from './pages/registration/RegistrationForm';
import NewRegistration from './pages/registration/NewRegistration';
import ManageRegistration from './pages/registration/ManageRegistration';
import ActiveInfo from './pages/website/infoDesk/ActiveInfo';
import StudentSearch from './pages/students/StudentSearch';
import StudentAccounts from './pages/students/StudentAccounts';
//import MarkSheetForm from './pages/course/MarkSheetForm';
//import MarkSheet from './pages/course/MarkSheet';
import MarkSheetDisplay from './pages/students/MarkSheetDisplay';
import StudentsInsights from './pages/insights/StudentsInsights';
import TopStoriesMain from './pages/website/topStories/TopStoriesMain';
import StaffForm from './pages/staff/StaffForm';
import StaffSearch from './pages/staff/StaffSearch';
import StaffAccounts from './pages/staff/StaffAccounts';
import LatestArrivalsMain from './pages/website/latestArrivalsLibrary/LatestArrivalsMain';
import UpComingMain from './pages/website/upcoming/UpComingMain';

function HomePage() {
  const themeReducer = useSelector(state => state.ThemeReducer)
  //const navigate = useNavigate();
  const dispatch = useDispatch()
  //const [isSidebar, setIsSidebar] = useState(true);
  const { loggedUser, sidebarStatus, setSidebarStatus} = useContext(ProductContext);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedLeftChange = (event) => {
   // console.log("clicked");
   event.preventDefault();
    //console.log("collapsed :", collapsed);
    //console.log("sidebar : ", sidebarStatus);
    setCollapsed(true);
    setSidebarStatus("Shrunk");
  };

  const handleCollapsedRightChange = (event) => {
    // console.log("clicked");
    event.preventDefault();
    // console.log("collapsed :", collapsed);
    // console.log("sidebar : ", sidebarStatus);
     setCollapsed(false);
     setSidebarStatus("Orginal");
   };
 

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
   // console.log("value");
    setToggled(value);
  };

  useEffect(() => {
    const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')
    const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')
    dispatch(ThemeAction.setMode(themeClass));
    dispatch(ThemeAction.setColor(colorClass));
    //console.log("sidebar status : ", sidebarStatus);
    //setCollapsed(false);
    //setSidebarStatus("Orginal");
    {/*if(loggedUser.isAuthenticated){
     navigate("/");
    }else{
      navigate("/login");
    }*/}
}, [dispatch, loggedUser])

return (
    <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
      <div className="app">

        {loggedUser.isAuthenticated && <Sidebar image={image}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedLeftChange={handleCollapsedLeftChange} 
        handleCollapsedRightChange={handleCollapsedRightChange}
        />}
        <main className={sidebarStatus==="Full"?"layout__content-full":sidebarStatus==="Orginal"?"layout__content-main":"layout__content"}>
        {loggedUser.isAuthenticated && <Topnav />}
        <div className="">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/registration/new" element={<NewRegistration />} />
            <Route path="/registration/manage" element={<ManageRegistration />} />
            <Route path="/student/search" element={<StudentSearch />} />
            <Route path="/student/marksheet" element={<MarkSheetDisplay />} />
            <Route path="/student/login" element={<StudentAccounts />} />
            <Route path="/staff/search" element={<StaffSearch />} />
            <Route path="/staff/register" element={<StaffForm formType={"New"}/>} />
            <Route path="/staff/login" element={<StaffAccounts />} />
            <Route path="/slider-viewer" element={<SliderViewer />} />
            <Route path="/gallery-viewer" element={<GalleryViewer />} />
            <Route path="/infodesk" element={<ActiveInfo />} />
            <Route path="/topstories" element={<TopStoriesMain />} />
            <Route path="/upcomingevents" element={<UpComingMain />} />
            {/*<Route path="/latestarrivals" element={<LatestArrivalsMain />} />*/}
            <Route path="/publications/vjtoday" element={<VJToday />} />
            <Route path="/insights/students" element={<StudentsInsights />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/list-admins" element={<ListAdmins />} />
            <Route path="/profile" element={<AdminForm />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
          </div>
        </main>
        </div>
                     {/*} <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
                   <ProSidebar />
                    <div className="layout__content">
                        <Topnav/>
                        <div className="layout__content-main">
                            <NavRoutes/>
                        </div>
                    </div>
  </div>*/}
    </div>
  );
}

export default HomePage;