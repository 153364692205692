import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';

export default function TopStoriesForm(props) {
    const {topStory, setTopStory, formType, setImageFile} = props;
    const [isDisabled, setIsDisabled]=useState(true);
    const inputRef = React.useRef();

    useEffect(()=>{
        if(formType==="View"){
        setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    },[])

    const handleFileSelection = (event)=>{
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
          setImageFile(event.target.files[0]);
          //var tprofile1 = document.getElementById("img1");
          //tprofile1.src=URL.createObjectURL(event.target.files[0]);
      }
  }

  const handleSelection=(e)=>{
    // e.preventDefault();
    //console.log("Event :", topStory.timelineDisplay);
    setTopStory({...topStory, timelineDisplay:!topStory.timelineDisplay});
   }

   const handleEventDate=(e)=>{
    const eventDate=e.target.value;
    const temp=new Date(e.target.value);
    const dateTimeFormatter = new Intl.DateTimeFormat("en-US", {dateStyle: 'long'});
    const formatDate = dateTimeFormatter.format(temp);
    console.log(formatDate);

    setTopStory({...topStory,
        timelineHeader:formatDate,
        eventDate:eventDate
    })
   }

  return (
    <div>
        <Card border="light" className="bg-white shadow-sm">
        <Card.Body>
            <Form>
            <div>
            {/*<Row>
                <Col>
                <Form.Label>Story Display</Form.Label>
                </Col>
                <Col>
              <Form.Group id="accessionNo">
              <Form.Check
                type="switch"
                id="custom-switch"
                label=""
                defaultChecked={topStory.timelineDisplay}
                isValid={true}
                disabled={isDisabled}
                onClick={(e) => handleSelection(e) }
              />
              </Form.Group>
              </Col>
            </Row>*/}
            <Row>
            <Col>
            <Form.Label htmlFor="exampleColorInput">Status</Form.Label>
            </Col>
            <Col>
            <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                labelId="status-label"
                id="status"
                variant="filled"
                value={topStory.displayStatus}
                disabled={isDisabled}
                onChange={(event)=>{setTopStory({...topStory, displayStatus:event.target.value})}}                >
                <option value="Active">Active</option>
                <option value="In-Active">In-Active</option>
                </Form.Select>
            </Col>
            </Row>
            <Row>
            <Col>
            <Form.Label htmlFor="exampleColorInput">Color picker</Form.Label>
            </Col>
            <Col>
            <Form.Control
                type="color"
                id="exampleColorInput"
                defaultValue="#563d7c"
                title="Choose your color"
                disabled={isDisabled}
                onChange={event=>setTopStory({...topStory, timelineColor:event.target.value})}
            />
            </Col>
            </Row>
            <Row>
                <Form.Group id="msgHead">
                    <Form.Label>Event Date</Form.Label>
                    <Form.Control required type="date" 
                    value={topStory.eventDate} onChange={(event)=>{handleEventDate(event)}}
                        disabled={isDisabled} />
                </Form.Group>
                </Row>
                <Row>
                <Col>
                    <Form.Label>Story Header</Form.Label>
                    </Col>
                    <Col>
                    <Form.Control required type="text"
                    value={topStory.timelineHeader}
                        disabled />
                    </Col>
                </Row>
               
                <Row>
                <Form.Group id="msgTitle">
                    <Form.Label>Story Title</Form.Label>
                    <Form.Control required type="text" placeholder="Enter Message Title" 
                    value={topStory.timelineTitle} onChange={(event)=>{setTopStory({...topStory,
                        timelineTitle:event.target.value})}} maxLength={100}
                        disabled={isDisabled}/>
                </Form.Group>
            </Row>
            <div m="50px">
            {/*imageFile && <div className="text-center">
            <img id="img1" alt="Selected Image" src={profileImage}
            style={{ height: 180, width: 180 }} />
            </div>*/}
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextImage">
            <Form.Label column sm="4">
                Select Image
            </Form.Label>
            <Col sm="8">
            <Form.Control type="file"
                controlId="formFileImage"  
                accept="image/*" ref={inputRef}
                onChange={handleFileSelection} />
            </Col>
            </Form.Group>
        </div>
            <Row>
                <Form.Group id="msgText">
                    <Form.Label>Story Details</Form.Label>
                    <Form.Control required as="textarea" placeholder="Message"  rows={5} 
                    value={topStory.timelineDescription} onChange={(event)=>{setTopStory({...topStory,
                        timelineDescription:event.target.value})}} maxLength={5000}
                        disabled={isDisabled}/>
                </Form.Group>
            </Row></div>
            
            </Form>
        </Card.Body>
        </Card>
    </div>
  )
}
